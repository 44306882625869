<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data" onkeydown="return event.key != 'Enter';">
  <v-container id="CALENDARIOPRENOTAZIONI" fluid tag="section">
      <v-row>
        <v-col :cols="isEditPrenotazione ? '4' : '12'">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-calendar-check"
            :title="$t('prenotazioni.elencoVeicoli')"
            class="px-5 py-3"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('prenotazioni.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>
                <v-col align="end">
                </v-col>

              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblMain"
              :items="prenotazioni.source"
              :headers="headersColumns"
              :search="search"
              item-key="id"
              :value="[prenotazioni.selected]"
              :items-per-page="-1"
              @click:row="onTableRowClick"
              single-select
              :loading="isloading_veicoli"
              class="elevation-1"
              loading-text="Caricamento dati in corso..."
            >
          </v-data-table>            
        </base-material-card>
      </v-col>

      <v-col v-if="isEditPrenotazione" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-calendar-check-outline"
            :title="$t('prenotazioni.elencoPrenotazioni') + ': ' + prenotazioni.selected.cSigla"
            class="px-5 py-3"
          >
            
            <v-row class="fill-height">
              <v-col>
                <v-sheet height="64">
                  <v-toolbar
                    flat
                  >
                    <v-btn
                      outlined
                      class="mr-4"
                      color="grey darken-2"
                      @click="setToday"
                    >
                      Oggi
                    </v-btn>
                    <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="prev"
                    >
                      <v-icon small>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="next"
                    >
                      <v-icon small>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu
                      bottom
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right>
                            mdi-menu-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Giorno</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Settimana</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Mese</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    locale="it-IT"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="loadPrenotazioni"
                  >
                  <template v-slot:event="{ event }">
                  <span>&nbsp;</span><strong>{{ event.oraIniz }}</strong> <strong>{{ event.oraFine }}</strong> {{ event.name }}
                  </template>
                  </v-calendar>
                  <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                  >
                    <v-card
                      color="grey lighten-4"
                      min-width="350px"
                      flat
                    >
                      <v-toolbar
                        :color="selectedEvent.color"
                        dark
                      >
                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      
                      <v-card-text>
                        Modello: <span v-html="selectedEvent.marcaModelloVeicolo"></span>
                      </v-card-text>
                      <v-card-text>
                        Data Prenotazione: <span v-html="selectedEvent.dataPren"></span>
                      </v-card-text>
                      <v-card-text>
                        Ora Inizio: <span v-html="selectedEvent.oraIniz"></span>
                      </v-card-text>
                      <v-card-text>
                        Ora Fine: <span v-html="selectedEvent.oraFine"></span>
                      </v-card-text>
                      <v-card-text>
                        Prenotato da: <span v-html="selectedEvent.prenotatoDa"></span>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="secondary"
                          @click="selectedOpen = false"
                        >
                          Chiudi
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>

            <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
              <v-btn id="btnChiudi" tile color="darkred" @click="onCloseDetail">
                <v-icon left> mdi-door-open </v-icon>
                {{ $t("chiudi") }}
              </v-btn>
            </v-row>
          </base-material-card>
      </v-col>
    </v-row>
      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>    
  </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import PrenotazioniService from "@/service/PrenotazioniService";
import VeicoliService from "@/service/VeicoliService";

@Component
export default class CalendarioPrenotazioni extends Vue {
  prenotazioni = {
    selected: null,
    source: [],
  };

  headersColumns = [
    {
      text: "Sigla",
      align: "left",
      value: "cSigla",
    },
    { text: "Modello", value: "marcaModelloVeicolo" },
    { text: "Targa", value: "cTarga" },
    { text: "N° Posti", value: "nNumPosti" },
    { text: "Tipo Trasporto", value: "tipoVeicoloTipTras.cDescrizione" },
    { text: "Ente", value: "dotazioneAttuale.enteDTO.cDescrizione" },
    { text: "Servizio", value: "dotazioneAttuale.servizioDTO.cDescrizione" },
    { text: "Polo", value: "dotazioneAttuale.servizioDTO.poloDTO.cDescrizione" },
    { text: "Tipo Sharing", value: "tipoVeicoloSharing.cDescrizione" },
    { text: "Utente Speciale", value: "utenteSpecialeDTO.cNomeCognome" },
  ];


  focus= ''
  type= 'month'
  typeToLabel= {
        month: 'Mese',
        week: 'Settimana',
        day: 'Giorno',
      }

  selectedEvent= {}
      selectedElement= null
      selectedOpen= false
      events= []
      colors= ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']
      names= ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party']


  // Variabili
  isEditPrenotazione = false;
  isCreatedCorrectly = false;
  isAlertDetailVisible = false;
  isAlertGridVisible = false;

  dimension = 0;
  
  messaggioAlert = "";
  alertType = "error";
  search = "";

  mounted() {
    if (this.isCreatedCorrectly)
      this.onContainerChange();

    //this.$refs.calendar.checkChange()
  }

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "W_PRENOTAZIONI");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true;
        var enteId = this.$store.state.enteSel.id;
        // TODO 
        this.loadVeicoli(enteId, true, false);
        // SOSTITUIRE CON CHIAMATA AL DB
        //this.prenotazioni.source = this.veicoli;
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }

  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblMain").offsetWidth;
      return self.dimension;
    });
  }

  onTableRowClick(veicPren, row) {
    this.prenotazioni.selected = veicPren;
    console.log("Veicolo selezionato: ");
    console.log(this.prenotazioni.selected);

    this.onContainerChange();
    this.loadPrenotazioni();

    this.isEditPrenotazione = true;
  }

  onCloseDetail() {
    this.isEditPrenotazione = false;
    this.onContainerChange();

  }

  viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      }

    getEventColor (event) {
        return event.color
      }

      setToday () {
        this.focus = ''
      }

      prev () {
        this.$refs.calendar.prev()
      }

      next () {
        this.$refs.calendar.next()
      }

      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      }
/*
      updateRange ({ start, end }) {
        const events = []

        const min = new Date(`${start.date}T00:00:00`)
        const max = new Date(`${end.date}T23:59:59`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)

        for (let i = 0; i < eventCount; i++) {
          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
          })
        }

        this.events = events
      }

      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      }
*/
/*
      loadEventsVeicolo() {
        const events = []
        for (let i = 0; i < this.prenotazioniVeicolo.length; i++) {
          var item = this.prenotazioniVeicolo[i];
          events.push({
            name: item.cTarga,
            dataPren: item.dataPrenotText,
            start: item.oraInizioText,
            end: item.oraFineText,
            color: 'blue',
            timed: true,
          })
        }
        this.events = events
      }
*/
  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

    /// **************************
    /// DATABASE
    /// **************************

  loadVeicoli(enteId, sharing, storico) {
    var self = this;
    self.isloading_veicoli = true;
    VeicoliService.getVeicoliInSharing(
      enteId,
      sharing,
      storico,
      (resp) => {
        self.isloading_veicoli = false;
        console.log("Veicoli:");
        console.log(resp);
        self.prenotazioni.source = resp;
      },
      (err) => {
        console.log("Error getVeicoliInSharing:");
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoVeicoli");
        self.showHide_alertGrid(5000);
        self.isloading_veicoli = false;
      }
    );
  }

  loadPrenotazioni() {
    var self = this;
    var enteId = this.$store.state.enteSel.id;
    var userId = -1;
    var storico = false;
    var veicoloId = this.prenotazioni.selected.id;

    PrenotazioniService.getPrenotazioni(
      enteId,
      userId,
      veicoloId,
      storico,
      (resp) => {
        console.log("getPrenotazioni");
        console.log(resp);

        self.prenotazioniVeicolo = resp;
        // Aggiorno gli eventi del veicolo
        //self.loadEventsVeicolo();
        const events = []
        for (let i = 0; i < self.prenotazioniVeicolo.length; i++) {
          var item = self.prenotazioniVeicolo[i];
          events.push({
            name: item.cTarga,
            marcaModelloVeicolo: item.marcaModelloVeicolo,
            dataPren: item.dataPrenotText,
            oraIniz: item.oraInizioText,
            oraFine: item.oraFineText,
            start: item.hOraInizio,
            end: item.hOraFine,
            prenotatoDa: item.utentePrenotDTO.cNomeCognome,
            color: 'blue',
            timed: true,
          })
        }
        self.events = events

      },
      (err) => {
        console.log("Errore getPrenotazioni");
        console.log(err);
      }
    );
  }

}

</script>

