import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Prenotazioni/';

class PrenotazioniService {
    getPrenotazioni(enteId, userId, veicoloId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("userId", userId);
        parametri.append("veicoloId", veicoloId);
        parametri.append("storico", storico);

        UtilsRequest.get(API_URL + "GetPrenotazioni", parametri,
        successFn,
        failFn
        );
    }

    getPrenotazione(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);

        UtilsRequest.get(API_URL + "GetPrenotazione", parametri,
        successFn,
        failFn
        );
    }

    getPrenotazioniDisponibili(enteId, userId, prenotazione, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idEnte", enteId);
        parametri.append("idUser", userId);
        parametri.append("tipoSharingId", prenotazione.tipoVeicoloSharing.id);
        if (Utils.isNullOrUndefinedOrEmpty(prenotazione.tipoVeicoloTipTras))
            parametri.append("tipoTraspId", -1);
        else
            parametri.append("tipoTraspId", prenotazione.tipoVeicoloTipTras.id);
        parametri.append("nPosti", prenotazione.nPosti);
        parametri.append("DataPrenot", prenotazione.dataPrenotText);
        parametri.append("oraIni", prenotazione.oraInizioText);
        parametri.append("oraFin", prenotazione.oraFineText);
        
        UtilsRequest.get(API_URL + "GetPrenotazioniDisponibili", parametri,
        successFn,
        failFn
        );
    }

    getTemplatePrenotazione(successFn, failFn){
        const parametri = new URLSearchParams();

        UtilsRequest.get(API_URL + "GetTemplatePrenotazione", null,
        successFn,
        failFn
        );
    }

    createPrenotazione(prenotazione, successFn, failFn){
        UtilsRequest.post(API_URL + "CreatePrenotazione", prenotazione, 
        successFn,
        failFn
        );
    }

    updatePrenotazione(prenotazione, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdatePrenotazione", prenotazione, 
        successFn,
        failFn
        );
    }

    deletePrenotazione(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        
        UtilsRequest.delete(API_URL + "DeletePrenotazione", parametri, 
        successFn,
        failFn
        );
    }

    terminaPrenotazione(prenotazioneDTO, successFn, failFn){
        UtilsRequest.put(API_URL + "TerminaPrenotazione", prenotazioneDTO, 
        successFn,
        failFn
        );
    }
}

export default new PrenotazioniService();